import React from "react";
import { useForm } from "react-hook-form";
import { navigate, Link } from "gatsby";
import { useMutation } from "react-query";

import { LayoutWrapper, Input, Select, Checkbox } from "../components";
import { login } from "../apis";
import { regions } from "../utils";

const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    setError,
    setValue,
    clearErrors,
  } = useForm();
  const mutation = useMutation(({ account, regionDomain }) =>
    login(account, regionDomain)
  );
  const watchPwd = watch("password");
  const watchRegion = watch("region");

  const onSubmit = async (data) => {
    const { region, ...restForms } = data;
    if (mutation.isLoading) return;
    try {
      const res = await mutation.mutateAsync({
        account: restForms,
        regionDomain: region.regionDomain,
      });
      window.location.href = `${region.cmsUrl}#token=${res.data.token}`;
    } catch (error) {
      // console.log("error", error.response);
      if (
        error.response.status === 401 &&
        error?.response?.data?.message === "Incorrect password"
      ) {
        setError("password", {
          type: "apiValidate",
          message: "Incorrect password. Please try again.",
        });
      } else if (error.response.status === 400) {
        setError("email", {
          type: "apiValidate",
          message: "Email not registered",
        });
      } else {
        setError("password", {
          type: "apiValidate",
          message: error?.response?.data?.message,
        });
      }
    }
  };

  const selectRegionCheck = () => {
    const hasCorrectRegion = regions.find(
      (region) => region.name === watchRegion?.name
    );
    if (!hasCorrectRegion) {
      setValue("region", { name: "" });
    }
    return !!hasCorrectRegion;
  };

  return (
    <LayoutWrapper
      title={"Login"}
      subTitle={"Welcome back to RoboticsCats"}
      question={
        <span onClick={() => navigate("/register")}>
          Don’t have an account yet?
        </span>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          label="LookOut Region"
          placeholder="Select your region"
          {...register("region.name", {
            required: {
              value: true,
              message: "This field is required.",
            },
          })}
          onMenuCloseNotInDom={selectRegionCheck}
          error={formState.errors?.region?.name}
        >
          {regions.map((region) => (
            <Select.Option
              value={region.name}
              key={region.value}
              onClick={() => {
                clearErrors("region");
                setValue("region", region);
              }}
            >
              {region.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          label="Email Address"
          placeholder="Email Address"
          {...register("email", {
            required: {
              value: true,
              message: "this field is required",
            },
          })}
          error={formState.errors?.email}
        ></Input>
        <Input
          label="Password"
          placeholder="Password"
          {...register("password", {
            required: {
              value: true,
              message: "this field is required",
            },
          })}
          type="password"
          typeToogle={watchPwd?.length > 0}
          error={formState.errors?.password}
        ></Input>
        <button type="submit" className="register__btn">
          {mutation.isLoading ? "Logging in..." : "Log In"}
        </button>
      </form>
      <span className="register__link" onClick={() => navigate("/forgot")}>
        Forgot password?
      </span>
    </LayoutWrapper>
  );
};

export default Login;
