import {
  GATSBY_AI_CLOUD_CMS_URL,
  GATSBY_EU_AI_CLOUD_CMS_URL,
  GATSBY_APAC_AI_CLOUD_CMS_URL,
} from "gatsby-env-variables";

const regions = [
  {
    name: "LATAM",
    value: "others",
    regionDomain: "lax",
    cmsUrl: GATSBY_AI_CLOUD_CMS_URL,
  },
  {
    name: "APAC",
    value: "asia pacific",
    regionDomain: "syd",
    cmsUrl: GATSBY_APAC_AI_CLOUD_CMS_URL,
  },
  {
    name: "EMEA",
    value: "europe",
    regionDomain: "bru",
    cmsUrl: GATSBY_EU_AI_CLOUD_CMS_URL,
  },
];

export default regions;
